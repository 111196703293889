.events {
    background-image: url(../../assets/images/ldg2.png);
    background-position: left;
    background-repeat: no-repeat;
    padding-bottom: 180px;
    @media screen and (max-width: 1000px) {
         background-position: 80% 100%;
         background-size: cover;
    }
}
.events-header {
    color: white;
    font-size: 3em;
}
.event-details-link {
    text-decoration: none;
}