.MuiList-root {
    background-color: white;
}
.app-bar {
    margin-left: none;
}
.MuiMenu-list {
    .MuiMenuItem-root {
         &:nth-child(3n-1) {
      background-color: black!important;
      border-color: black !important;
      width: 80%;
    }
    &:nth-child(3n-2) {
      background-color: white !important;
      border-color: white !important;
    }
    &:nth-child(3n-3) {
      background-color: white !important;
      border-color: white !important;
    }
    .MuiTypography-root {
        font-weight: bold;
        color: #7851A9;
    }
 } 
}