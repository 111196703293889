.music-row {
    overflow: auto;
    width: 100%;
}
.album-cover {
height: 320px;
        width: 320px;
}
.image-background {
    background-color: white;
    border-radius: 10px;
    height: 70px;
}
.h1 {
   font-size: 4em;
}
.music-platforms-row{
    justify-content: center;
	align-items: center;
    overflow: auto;
    width: 100%;
     
}
@media screen and (max-width: 1112px) {
    .music-platforms-row{
        overflow: auto;
        width: 100%;
        justify-content: initial;
        align-items: initial;
    } 
}
.music-header {
    color: white;
    font-size: 3em;
}