.css-o69gx8-MuiCardMedia-root {
  width: unset;
}
.small-screen-mini-bios {
  width: 90%;
}
.css-1suoufe-MuiTypography-root {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.image-row {
    overflow: auto;
    /* -webkit-justify-content: initial; */
}
.image-row-img {
        width: 300px;
        /* height: 300px; */
    }

.card-img { 
transition: all .2s ease-in-out; 
background-color: black;
width: unset;
}

.card-img:hover { 
transform: scale(1.1); 
}
.mini-bio-card {
  margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
  margin-right: auto;
  align-self: center;
  justify-self: center;
}
.mini-card-content {
  flex: 1 0 auto;
  display: flex;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  img {
    width: 16px;
    height: 17px;
  }
  /* rgba(105,105,105, .2); */
}
@media screen and (min-width: 1367px) {
  .image-row {
    justify-content: center;
    align-items: center;
  }
}
  @media screen and (max-width: 1024px) {
    .small-screen-mini-bios {
      width: 100%;
    }
    .main-image {
      width: 288px;
      height: 192px;
    }

    .emmy-img {
      width: 288px;
      height: 192px;
    }
    .image-row-img {
     height: 122px;
        width: 140px; 
    }
    .sub-card {
      width: 100%;
    }
  }

