.video-row {
    flex-direction: "row"
}
.yt-iframe {
    border: 0px;
}
@media screen and (max-width: 1024px) {
    .yt-iframe {
    width: 187px;
    height: 105px;
}
}
.videos-header {
    color: white;
    font-size: 3em;
}