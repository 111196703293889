.scores-header {
    color: white;
    font-size: 3em;
}
.main-container {
    width: 70%;
}
.scores-yt-iframe {
    border: 0px;
    width: 560px;
	height: 315px;
    padding-top: 20px;
}
@media screen and (max-width: 1024px) { 
    .main-container {
        width: 100%;
    }
    .score-image-grid {
        width: 300px;
        height: 403px;
    }
     .scores-yt-iframe {
    /* width: 187px;
    height: 105px; */
    width: 100%;
}
}