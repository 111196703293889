 .header {
    background-image: url(../../assets/images/grand-gray.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
   
}
 .header-h1 {
        font-family: cursive;
		font-size: 7em;
		color: #4B006E;
		padding-top: 60px;
        text-shadow: 1px 1px 0 #cfb53b;
    }

 @media screen and (max-width: 1024px) { 
    .header {
        background-position: center;
    }
    .header-h1 {
        font-size: 5em;
    }
    .emmy-video {
        width: 320px !important;
        height: 180px !important;
    }
 }


.right {
    text-align: right;
    padding-right: 400px;
    padding-top: 170px;
    padding-bottom: 10px;
}
.videos-section {
    padding-top: 100px;
}
.card-link {
    text-decoration: none;
}
